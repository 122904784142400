import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import Banner from '../components/Banner';
import Quotes from '../components/quotes';

import pic01 from '../assets/images/2020-02_UtahSymphony_creditAustenDiamond_4.jpg';
import pic02 from '../assets/images/university-sports.jpg';
import pic03 from '../assets/images/business-professionals.jpg';
import pic04 from '../assets/images/pic05.jpg';
import pic05 from '../assets/images/digital-solutions.jpg';
import pic06 from '../assets/images/publishing-about-us.jpg';

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="Mills Publishing Inc. - Home"
          meta={[
            {
              name: 'description',
              content:
                'We provide advertising opportunities for businesses and marketing professionals through the medium of performing arts playbills, university sports programs, professional journals and periodicals throughout Utah.',
            },
            {
              name: 'keywords',
              content:
                'theater, arts, festivals, playbills, university sports programs, professional journals, Utah, Mills Publishing',
            },
          ]}
        ></Helmet>

        <Banner />

        <div id="main">
          <section id="two">
            <div className="inner">
              <Quotes />
            </div>
          </section>

          <section id="one" className="tiles">
            <article style={{ backgroundImage: `url(${pic05})` }}>
              <header className="major">
                <h3>See our Work</h3>
                <p>
                  View our publications in a digital friendly format and see
                  what we do.
                </p>
              </header>
              <Link to="/digital-publications" className="link primary"></Link>
            </article>
            <article
              style={{ backgroundImage: `url(${pic01})` }}
              alt="Utah Symphony at Abravanel Hall"
            >
              <header className="major">
                <h3>Arts Programs</h3>
                <p>Create Success through Art.</p>
              </header>
              <Link to="/arts-programs" className="link primary"></Link>
            </article>
            <article style={{ backgroundImage: `url(${pic02})` }}>
              <header className="major">
                <h3>University Sports Programs</h3>
                <p>Put Your Business in the Game.</p>
              </header>
              <Link
                to="/university-sports-programs"
                className="link primary"
              ></Link>
            </article>
            <article style={{ backgroundImage: `url(${pic03})` }}>
              <header className="major">
                <h3>Professional Journals</h3>
                <p>Target high-end Professionals.</p>
              </header>
              <Link to="/professional-journals" className="link primary"></Link>
            </article>
            <article style={{ backgroundImage: `url(${pic04})` }}>
              <header className="major">
                <h3>Outdoor Sports Guide Magazine</h3>
                <p>Active results to active people.</p>
              </header>
              <Link
                to="/publications/outdoor-sports-guide"
                className="link primary"
              ></Link>
            </article>

            <article style={{ backgroundImage: `url(${pic06})` }}>
              <header className="major">
                <h3>About Us</h3>
                <p>See what makes us tick.</p>
              </header>
              <Link to="/about-us" className="link primary"></Link>
            </article>
          </section>
        </div>
      </Layout>
    );
  }
}

export default HomeIndex;
