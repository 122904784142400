import React from 'react';

const Banner = (props) => (
  <section id="banner" className="major">
    <div className="inner">
      <header className="major">
        <h1>Over 30 Publications to Maximize Your Reach!</h1>
      </header>
      <div className="content">
        <span>
          <h3>
            Discover the power of targeted print and digital advertising in
            today's tech-driven world.
          </h3>
          We provide advertising opportunities for businesses and marketing
          professionals through the medium of performing arts playbills,
          university sports programs, professional journals and periodicals
          throughout Utah.
        </span>
        <br />
        <br />
        <ul className="actions">
          <li>
            <a href="/digital-publications" className="button scrolly">
              View Our Work
            </a>
          </li>
        </ul>
        <ul className="actions">
          <li>
            <a href="#one" className="button next scrolly">
              Get Started
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
);

export default Banner;
