import React from 'react';

function Quotes(props) {
  const millsQuotes = [
    {
      quote: `I have enjoyed working with Mills Publishing for several years now. I would highly recommend them (and have!) to anyone looking for the utmost professionalism, the highest skill level, and the most helpful, flexible and accommodating staff around.`,
      byline: '~ Pioneer Theatre Company',
    },
    {
      quote: `Since 1985, BMW of Murray has been working with Mills Publishing and placing ads in their playbills on a consistent basis. I am happy with the exposure that we are receiving through playbill advertising. For us, playbill advertising has proven to be a vital and important part of our advertising plans as it directly and effectively targets our customer demographic.`,
      byline: '~ Dave Clark, General Manager, BMW of Murray',
    },
    {
      quote: `From Ballet West to the University of Utah football, Mills Publishing  helps O.C. Tanner Jewelers reach our target audience effectively and efficiently on a continual basis. Advertising in playbills and programs has been an important part of our overall strategy providing an excellent medium for our message. Our longstanding relationship with Mills is one we expect to continue on for many years as they continue to provide the audiences we seek.`,
      byline: '~ O.C. Tanner Jewelers',
    },
    {
      quote: `Since 1862, my family has represented Steinway & Sons, the highest quality piano in the world. Mills Publishing has done much to preserve our honorable image and reputation. They ensure that Daynes Music Company is consistently represented in the most favorable and reputable venues throughout the state of Utah in an elegant and respectable manner. We are grateful for our association with Mills Publishing and the great Steinway tradition they have served.`,
      byline: '~ Gerald “Skip” Daynes, President. Daynes Music Company',
    },
  ];

  const randomQuote =
    millsQuotes[Math.floor(Math.random() * millsQuotes.length)];

  return (
    <>
      <header className="major">
        <h2>Our Clients</h2>
      </header>
      <blockquote className="millsQuote">
        <p>“{randomQuote.quote}”</p>
        <strong>{randomQuote.byline}</strong>
      </blockquote>
    </>
  );
}

export default Quotes;
